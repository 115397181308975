import { useState } from 'react'
import { menuItems } from '../../constant/navItems' ;
import {
    Link,
    useHistory
  } from 'react-router-dom';
import { logout } from '../../Utils/login';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SideBar=(props)=>{
    const history = useHistory();
    const [ page, setPage ] = useState (''); 
    const location = useLocation();


    
    const changePage = (currentPage, e, currentValue) =>{
        if(currentValue === 'checkinAdmin' && location.pathname !== "/CheckinAdmin") {
            e.preventDefault();
            props.setQRKiosk();
        }
        setPage ( currentPage )
    }

    const onLogout = (e) => {
        e.preventDefault();
        logout();
        history.push('/First');
    }
    

    const isRestrictPage = localStorage.getItem ( 'isRestrictPage' );

    
    const menuList = isRestrictPage === 'true' ? ['checkinAdmin'] : props.menuList;
    return(
        <>
            {props.children}
            <ul className="navbar-nav">
            {
                menuList.map((currentValue)=>{
                    const activeClass = history.location.pathname === menuItems[currentValue].router ? 'active' : '';
                    return (
                    <li key={currentValue.label}>
                        <Link className = {'nav-link fw-600 '+ activeClass} to = { menuItems[currentValue].router } onClick = { (e) => changePage ( menuItems[currentValue].label, e, currentValue) }>
                            {menuItems[currentValue].icon && <i className={"f-s23 "+menuItems[currentValue].icon}></i>}
                            <span>{menuItems[currentValue].label}</span>
                        </Link>
                    </li>
                    )
                })
            }
            <li key={"logout"}>
                <a className = {'nav-link fw-600'} href="#" onClick={onLogout}>
                    <i className="f-s23 bi bi-arrow-bar-left"></i>
                    <span>Logout</span>
                </a>
            </li>
        </ul>
        </>
    )
}
export default SideBar