import React, {useState, useRef, useContext} from 'react';
import Logo from '../images/logo.png';
import SideBar from './Common/SideBar';
import Button from './Common/Button.js';
import {
    useHistory
  } from "react-router-dom";
import { userDetailsContext, mobileMenuHandler } from "../context";
import Actions from './Common/Actions.js';
import Modal from './Common/Modal.js';
import ModalFooter from './Common/ModalFooter.js';


const Layout = (props) =>{
    const history = useHistory();
    const overlay = useRef(null);
    const navBar = useRef(null);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const [isShowNavBar, setIsShowNavBar] = useState(false);
    const userDetail = useContext(userDetailsContext);

    const [ showPopupScanQR, setShowPopupScanQR] = useState(false);
    


    //for desktop view
    const changeScreenSize=(e)=>{
        e.preventDefault();
        setIsLargeScreen(!isLargeScreen);
    };
    //for mobile view
    const showNavBar = ()=>{
        setIsShowNavBar(!isShowNavBar);
    };

    const onClickHome = (e) => {
        e.preventDefault();
        history.push('/')
    }


    const setQRKiosk = () => {
        setShowPopupScanQR(true);
    }

    const hideModal = () => {
        setShowPopupScanQR(false);
    }

    const gotoScanQR = () => {
        hideModal();
        localStorage.setItem ( 'isRestrictPage', 'true' );
        history.push('/CheckinAdmin');
    }


    const actions = [  
        {label : "No", className : "btn-secondary", onClick : hideModal},
        {label : "Yes", className : "btn-primary", onClick : gotoScanQR }
    ] ;
   

    const hideNavBar = (e)=>{
        if(e.target == overlay.current){
            setIsShowNavBar(false);
        }
    }
    
    const navBarClass = isShowNavBar? 'd-block ' : 'd-none';
    const className = isLargeScreen ? "main-content-large-screen" : "main-content-normal-screen" ;
    const navBarSize = isLargeScreen ? "nav-large-screen" : "nav-normal-screen";
    return(
        <>
            <div>
                <div className = {'bg-white border-right overflow-auto nav-bar nav-bar-md d-none d-md-block '+ navBarSize}>
                    <SideBar menuList={userDetail.menu || []} setQRKiosk={setQRKiosk}>
                        <div className='logo-image-wrap'>
                            {!isLargeScreen ? <a href='#' onClick={onClickHome} className='logo-image'><img src={Logo} alt='logo'/></a> : null}
                            <a href='#' title='Exapnd Collapse Menu' className='menu-collape'><i class={`bi ${!isLargeScreen ? 'bi-arrow-left-square' : 'bi-arrow-right-square'}`} onClick = {changeScreenSize}></i></a>
                        </div>
                    </SideBar>
                </div>  
                <div className = {'nav-bar-overlay d-lg-none nav-bar ' + navBarClass} ref = {overlay} onClick = {hideNavBar}>
                    <div className = 'bg-white overflow-auto nav-normal-screen-sm h-100' ref = {navBar}>
                        <SideBar menuList={userDetail.menu || []} setQRKiosk={setQRKiosk}>
                            <div className='logo-image-wrap'>
                                { <a href='#' onClick={onClickHome} className='logo-image'><img src={Logo} alt='logo'/></a> }
                            </div>
                        </SideBar>
                    </div>
                </div>
            {showPopupScanQR && <Modal>
                <p>If you go to the Scan QR Kiosk, you can't return to any other page.<br/> The only option to access other pages is to log out and log in again. Continue? <br/><br/>
                <span>Note: Scan QR Kiosk helps to scan Employee QR only.</span></p>
                <ModalFooter>
                    <Actions actions = {actions}/>
                </ModalFooter>
            </Modal>}
                <div className="main-content">
                    <div className = {className}>
                        <div className="bg-white rounded">
                            <mobileMenuHandler.Provider value={{showNavBar}}>
                                {props.children}
                            </mobileMenuHandler.Provider>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}
export default Layout;