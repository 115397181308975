//components
import { useState } from "react";
import FormLabel from "./FormLabel";
import Modal from "./Modal";
import ModalFooter from "./ModalFooter";

const Select=(props)=>{
    const { options = [], className = " ", onChange, label, name, value, disabled = false,mandatory = true, keyValue=false, tooltip } = props;

    const [ showModal, setShowModal ] = useState(false);
    
    const onChangeHandler=(event)=>{
        if(onChange) onChange(event)
    }

    const hideModal = () => {
        setShowModal(false);
    }

    return(
        <>
            {label && <FormLabel label = {label} mandatory = {mandatory} tooltip={tooltip} onClickTooltip={()=>setShowModal(true)}/>}
            <select disabled = {disabled} value = {value} className = {"form-select f-s14  border "+className} name = {name} onChange = {onChangeHandler}>
                {options.length &&
                    options.map((currentValue, index)=>{
                        return(
                            <option key={ keyValue ? index : currentValue.value} value={currentValue.value}>{currentValue.name}</option>
                        )
                    })
                }
            </select>
            { !!showModal && tooltip && <Modal title = {tooltip.title} hideModal = {hideModal}>
                <>
                    {tooltip.content}
                    <ModalFooter/>
                </>
            </Modal>}
       </>
    )
}
export default Select;