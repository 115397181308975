import FormLabel from "./FormLabel" ;
const TextArea = (props) => {
    const { label, value, onChange, className, mandatory = true, name, maxLength="200" } =props
    const onChangeHandler = (e) => {
        if (onChange) {
            onChange (e)
        }
    }
    return (
        <>
        {label && 
            <FormLabel label = {label} mandatory = {mandatory}/>
        }   
        <div className = "input-group">
            <textarea maxLength={maxLength} className={"form-control f-s14 "+className} value = {value} name = {name} onChange = { (e) => onChangeHandler (e)}/>
        </div>
        </>
    )
}
export default TextArea