//components
import Button from './Button';
import Pagination from './Pagination';
import Actions from './Actions';
//utils
import {splitPascalCase} from '../../Utils/splitPascalCase'
import { useContext, useEffect, useState } from 'react';
const Table=(props)=>{
  const [page, setPage] = useState(1) ;
  const itemsPerPage= props.paginationDetail ? props.paginationDetail.itemsPerPage : 10;
  const totalItems  = props.paginationDetail ? props.paginationDetail.total : 10;

  const routePage =(pageName)=>{
    let currentPage = page;
    if(pageName == 'prev') {
      if(page != 1) currentPage = page -1;
    }
    else if(pageName == 'next') {
      const totalPage = totalItems/itemsPerPage;
      if(currentPage != totalPage) currentPage = currentPage + 1;
      
    }
    else{
      currentPage = pageName;
    }
    if(props.routePage) props.routePage(currentPage);
    setPage(currentPage);
  }
  const searchPage = (pageNo) =>{
    setPage(pageNo);
  }
  const onChangeRowsPerPage = (page) => {
    if(props.onChangeRowsPerPage) props.onChangeRowsPerPage(page)
  }
  const { header = [], body = [], isLoader=false } = props;
  // useEffect(()=>{
  //   let showAccordions = body.map((accordion, index)=>{
  //     if(index == 0){
  //       return true;
  //     }else{
  //       return false;
  //     }
  //   })
  //   setAccordionId(showAccordions);
  // },[true]);
  
  const [accordionId, setAccordionId] = useState([]);
  const showHideAccordion = (index)=>{
    let showAccordions = [...accordionId];
    showAccordions[index] = !showAccordions[index];
    setAccordionId(showAccordions)
  }
  return(
    <>
    <div className = 'd-none d-lg-block'>
    <table className="table f-s14 m-0 secondary-family">
        <thead  className="secondary-family f-s14 bg-light">
          <tr>
              {header.map ( (head) => {
                const label = head.label || splitPascalCase(head.key)
                return <th key={head.key}> {label} </th>
              } ) }
          </tr>
        </thead>
        <tbody>
              {isLoader && 
              <tr>
              <td colSpan={header.length}> <div className='text-center'>
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </td>
            </tr>
            }
          {!isLoader && body.map((items,index)=>{
              return(
                <tr key={index} className="pl-20 pr-20">
                    { header.map((head,index ) => {
                      if( head.key=="action" ){
                        return( 
                          <td key = {head.actions}>
                            {head.actions.map((button, buttonIndex)=>{
                              const onClickHandler = (items) => {
                               if(button.onClick) button.onClick(items)
                              }
                              return <Button key={buttonIndex}  className = {'btn-sm border-0 ' + button.className} onClick = {() => onClickHandler(items)} title = {button.title}>{button.label}</Button>
                            
                            })}
                          </td>
                        )
                      }
                      else{
                        return  <td key = { index } > { items[head.key] }</td> 
                      }   
                        })
                    }
                </tr>
              )
          })}
        </tbody>
      </table>
    </div>
      { !props.isHidePagination && <Pagination routePage = {routePage} onChangeRowsPerPage={onChangeRowsPerPage} searchPage = {searchPage} selectMenu = {[{name : 2, value : 2}]} itemsPerPage = {itemsPerPage} totalItems = {totalItems} currentPage = {page}/>}
      <div className = 'd-lg-none mobile-grid'>
      {isLoader &&  <div className='text-center'>
            <div className="spinner-border text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
      {!isLoader &&
        body.map((items, rowIndex)=>{
            return(
              <>
              <div key = {rowIndex} className = 'shadow-sm mb-2'>
                {header.map((head, index)=>{
                  const title = head.label || splitPascalCase(head.key);
                  if(index != 0){
                    const accordionClass = !accordionId[rowIndex]? 'd-block' : 'd-none';
                    return(
                      <div key = {index} className = { accordionClass + ' px-3 d-flex mb-2 pb-2'}>
                        <div className = 'col-4 label'>
                          {title}
                        </div>
                        {head.key != 'action' &&
                          <div className = 'col'>
                            {items[head.key]}
                          </div>                      
                        }
                        {head.key == 'action' &&
                          <div className = 'col'>
                            {/* {<Actions actions = {head.actions}/>} */}
                            {head.actions.map((button, buttonIndex)=>{
                              const onClickHandler = (items) => {
                                if(button.onClick){
                                  button.onClick(items);
                                } 
                              }
                              return <Button key={buttonIndex}  className = {'btn-sm border-0 ' + button.className} onClick = {() => onClickHandler(items)} title = {button.title}>{button.label}</Button>
                            })}
                          </div>                      
                        }
                      </div>
                    )
                  }else{
                    const accordionClass = !accordionId[rowIndex]? 'bg-primary text-white font-weight-bold' : '';
                    return(
                    <div key = {index} className = {'px-3 d-flex mb-2 pt-2 pb-2 cursor-pointer ' + accordionClass} onClick = {()=>showHideAccordion(rowIndex)}>
                      <div className = 'col-4 label'>
                        {title}
                      </div>
                      <div className = 'col d-flex justify-content-between'>
                        <span>{items[head.key]}</span>
                        <i className = { !accordionId[rowIndex]? 'bi bi-caret-up-fill' : 'bi bi-caret-right-fill'}></i>
                      </div>                      
                    </div>       
                    )
                  }
                })}
              </div>
              </>
            )
        })
      }
      </div>
      </>
    )
}
export default Table