import { useContext, useEffect, useRef, useState } from "react";
import {useHistory } from 'react-router-dom';

//componenets
import Layout from "../Components/Layout";
import PageHeader from "../Components/Common/PageHeader";
import Accordion from "../Components/Common/Accordion" ;
import Form from '../Components/Common/Form';
import ErrorMessage from "../Components/Common/ErrorMessage";
import Actions from '../Components/Common/Actions' ;
import Status from "../Components/Common/Status" ;
import AutoComplete from "../Components/Common/Autocomplete";
import AddBranch from "../Components/AddBranch";
import DatePickerReact from "react-datepicker";
import { userDetailsContext } from "../context";


//utils
import {isAutoCompleteValid} from '../Utils/isValidAutoComplete';
import {validation} from '../Utils/validation';
import { splitPascalCase } from "../Utils/splitPascalCase";

//services
import { getBranch } from '../services/branch';
import {  getTeams, createTeam as createTeamService } from '../services/team';
import { getShifts } from '../services/shift';
import { getDesignationList } from '../services/designation';
import {getLeavesList} from '../services/leave';
import { createEmployee as createEmployeeService, updateEmployee as updateEmployeeService, getEmployeesListService, getShiftDetail } from '../services/employee';
import { getCheckinTypes } from '../services/checkinTypes';

// constant
import {employeeStatusList, gpsTrackingList} from '../constant/employee' ;
import { shiftTypesList } from '../constant/shiftTypes' ;
import moment from "moment";
import Toast from "../Components/Common/Toast";
import Button from "../Components/Common/Button";
import ErrorAlert from "../Components/Common/ErrorAlert";
import Modal from "../Components/Common/Modal";

const AddEmployee = (props) => {
    const router = useHistory();

    // state for set services
    const [ branchList, setBranchList ] = useState([]);
    const [ teamList, setTeamList ] = useState([]);
    const [ shiftList, setShiftList ] = useState([]) ;
    const [ designationList, setDesignationList ] = useState([]);
    const [leaveTypeList, setLeaveTypeList] = useState([]);
    const [ isShowBranchModal, setIsShowBranchModal ] = useState(false);
    const [checkinTypes, setCheckinTypes] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState({});
    const [ createBranchName, setCreateBranchName] = useState("");
    const [ isShowChangeShift, showChangeShift] = useState(false);
    const roleName = useContext(userDetailsContext).role;

    const isEditEmployee = props.match.params.id ? true : false;

    // state for set status
    const [status, setStatus] = useState({ 
        showStatus : false, 
        spinner : false, 
        success : false, 
        successMessage : '' 
    });

    //state for show and hide image cropper 
    const [isShowImageCropper, setIsShowImageCropper] = useState(false);

    // state for show and hide accordians body
    const[isShowAccordions, setIsShowAccordions] = useState([true, true, true, true]);

    // state for store new employee details
    const [ employee, setEmployee ] = useState({});

    // state for errormessage
    const [errorMessage, setErrorMessage] = useState({});

    const [serverErrorMessage, setServerErrorMessage] = useState("");


    
    const commonMessageElement = useRef(null);

    const services = ()=>{
        // get from server
        const pagination = { total:100, itemsPerPage:100, page:1};
        const branchPromise = getBranch(pagination).then( (results)=> {
            let newBranchList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setBranchList([...newBranchList]);
            return newBranchList;
        }).catch(errorHandleService);

        const teamPromise = getTeams(pagination).then( (results)=> {
            let newTeamList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setTeamList([...newTeamList]) ;
            return newTeamList;
        }).catch(errorHandleService);

        getShifts(pagination).then( (results)=> {
            let newShiftList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setShiftList([...newShiftList]);
        }).catch(errorHandleService);

        getDesignationList(pagination).then( (results)=> {
            let newDesignationList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setDesignationList([...newDesignationList]);
        }).catch(errorHandleService);

        // getLeavesList(pagination).then((results)=>{
        //     let newLeaveList = results.data && results.data.result && results.data.result.length && results.data.result || [];
        //     setLeaveTypeList([...newLeaveList]);
        // });

        getCheckinTypes().then((results)=> {
           const checkinType = results.data.map((type)=> {
                return { ...type, value : type.id };
            })
            setCheckinTypes([...checkinType])
        }).catch(()=>{
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        });

        return [teamPromise, branchPromise]
    }

    const hideErrorMessage = () => {
        setServerErrorMessage('')
    }

    const errorHandleService = (error) => {
        if(error.response.status == 401) {
            router.push('/First') ;
        }
        else {
            setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        }
    }

    const onCreateTeam = (val) => {
        if ( val ){
            const data = { name : val, description : "-"  }
            setIsLoading ( true );
            createTeamService (data).then ( (results)=> {
                const newTeam = { id : results.data.insertId, name : data.name, description : data.description, lead_id: 1  };
                setTeamList( [newTeam, ...teamList] );
                setShowToast({isShow: true, message: "Created Team Successfully!"});
                setTimeout(()=>{
                    setShowToast({isShow: false, message: ""})
                }, 2000);
                setIsLoading ( false );
            }).catch((error)=>{
                setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                setIsLoading ( false );
            })
        }    
    }

    const onCreateBranch = (val) => {
        setCreateBranchName(val);
        setIsShowBranchModal(true);
        setIsLoading ( true );
    }

    const closeToastHandler = () => {
        setShowToast({isShow: false, message: ""})
    }

    const addBranchHandler = (data) => {
        const pagination = { total:100, itemsPerPage:100, page:1};
        const branchPromise = getBranch(pagination).then( (results)=> {
            let newBranchList = results.data && results.data.result && results.data.result.length && results.data.result || [];
            setBranchList([...newBranchList]);
            return newBranchList;
        }).catch(errorHandleService);
        Promise.all([branchPromise]);
        setIsShowBranchModal(false);
        setCreateBranchName("");
        setIsLoading ( false );
        setShowToast({isShow: true, message: "Created Branch Successfully!"});
        setTimeout(()=>{
            setShowToast({isShow: false, message: ""})
        }, 2000);
    }

    const closeBranchModal = () => {
        setIsShowBranchModal(false);
        setIsLoading ( false );
        setCreateBranchName("");
    }


    useEffect(async ()=> {
            const promises = services();
            if(props.match.params.id) {
                const teamBranches = await Promise.all(promises);

                const teams = teamBranches[0];

                const branches =  teamBranches[1];

                Promise.all([getEmployeesListService(props.match.params.id), getShiftDetail(props.match.params.id)]).then(([employeeDetail, shiftDetailResult])=>{
                    const {id, employee_id="", first_name, gps, last_name, mail_id, mobile, qr_code, dob,  checkin_type_id, workspace_id, workspace_name, team_id, team_name, designation_id, 
                    designation_name, leave_type_id, leave_type_name, salary, teamRoles=[], branchRoles=[], address, timezone, created_at, updated_at, status} = employeeDetail.data;

                    const { shift_id, name } = shiftDetailResult.data.currentShift;

                    const labelTeamRoles = teamRoles.map(item =>  {
                        const labelTeam = teams.find(tm => tm.id === item.value)
                        return {...item, label: labelTeam ? labelTeam.name: '', id: item.value};
                    });

                    const labelBranchRoles = branchRoles.map(item =>  {
                        const labelTeam = branches.find(bh => bh.id === item.value)
                        return {...item, label: labelTeam ? labelTeam.name: '', id: item.value};
                    })
                    
                    const employeeObj = {...employee, firstName:first_name, lastName:last_name, email:mail_id, checkinType:checkin_type_id, gps, 
                        branch:{ id: workspace_id, value: workspace_id, label: workspace_name}, team: {id: team_id, value:team_id, label:team_name }, 
                        designation: { id: designation_id, name: designation_name}, salary, employeeStatus: status, QRCode: qr_code, 
                        leaveType:{ id: leave_type_id, name: leave_type_name}, shifts: {value:shift_id, label:name}, 
                        address, dob, mobile, shift_id: shift_id, shiftDetail: shiftDetailResult.data,
                        teamRoles: labelTeamRoles, branchRoles: labelBranchRoles};

                    if(labelTeamRoles && labelTeamRoles.length) {
                        const role = roleTypeToAccessList[0];
                        employeeObj.roleTypeToAccess = {...role, label: role.name, value: role.id};
                    }

                    if(labelBranchRoles && labelBranchRoles.length) {
                        const role = roleTypeToAccessList[1];
                        employeeObj.roleTypeToAccess = {...role, label: role.name, value: role.id};
                    }
                    
                        if(shiftDetailResult.data.nextShift) {
                            employeeObj.new_shift = { value: shiftDetailResult.data.nextShift.shift_id, label:  shiftDetailResult.data.nextShift.name};
                            employeeObj.new_shift_start_from = moment(shiftDetailResult.data.nextShift.start_from).toDate();
                        }
                    setEmployee(employeeObj);
                }).catch(()=>{
                    setServerErrorMessage(`Oops! Something went wrong. 
            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                });
            }
            else {
                setEmployee({
                    imageURL : 'profilePic.png',
                    gps : 1, 
                    checkinType :  1, 
                    employeeStatus : 1
                });
            }
    },[]) ;

    const personalInformation = {
        col1 : [
            { row : [{ type : 'text', key : 'firstName' }, { type : 'text', key : 'lastName'}] },
            { row : [ {type : 'email', key : 'email', validationType : 'email' },  {type : 'text', key : 'mobile', label : 'Mobile number', isMandatory : false, emptyCheck : false}]},
            { row : [
                { type : 'date', key : 'dob', label : 'Date of birth', isMandatory : false, emptyCheck : false},
                {type : 'textarea', key : 'address', isMandatory : false, emptyCheck : false}
            ]},
        ]
    };

    const checkinTypesDetail = () => {
        return (
            <ul style={{listStyle:"none"}} className="tooltip-text">
            <li>
              <h6><strong>1) Branch QR</strong></h6>
              <p><strong>What it is:</strong> Each branch or location has a unique QR code.</p>
              <p><strong>How it works:</strong> Employees scan the branch's QR code using their mobile device to check in and out.</p>
              <p><strong>Use case:</strong> Suitable for workplaces with multiple locations or branches, providing flexibility for employees to check in at different locations.</p>
            </li>
            <li>
              <h6><strong>2) Employee QR</strong></h6>
              <p><strong>What it is:</strong> Each employee has a personalized QR ID card.</p>
              <p><strong>How it works:</strong> Employees scan their QR ID card at a kiosk device to check in and out.</p>
              <p><strong>Use case:</strong> Ideal for workplaces with a central kiosk or where physical presence verification is needed.</p>
            </li>
            <li>
              <h6><strong>3) Direct</strong></h6>
              <p><strong>What it is:</strong> No QR code is required.</p>
              <p><strong>How it works:</strong> Employees can directly check in and out from their mobile or web application.</p>
              <p><strong>Use case:</strong> Convenient for remote workers or workplaces where QR code scanning isn't feasible.</p>
            </li>
          </ul>
        )
    }

    const otherInformation = {
        col1 : [
            { row : 
                [
                    {
                        type : 'autocomplete', key : 'team', options : teamList, emptyCheck : true, 
                        validationType : 'autocomplete', 
                        optionText: <><i class="bi bi-plus-circle"></i> Type some name to create team</>,
                        handleCreate: roleName === 'admin' || roleName === 'branchLead'  ? onCreateTeam : false, isLoading, 
                        isAllowCreate: roleName === 'admin' || roleName === 'branchLead'  ? true : false
                    },
                    {
                        type : 'autocomplete', key : 'branch', options : branchList, emptyCheck : true, 
                        optionText: <><i class="bi bi-plus-circle"></i> Type some name to create branch</>, validationType : 'autocomplete',  
                        handleCreate: roleName === 'admin' ? onCreateBranch : false, isLoading, 
                        isAllowCreate: roleName === 'admin' ? true : false
                    }
                ]
            
            },
            { row : [
                { type : 'select', key : 'checkinType', options : checkinTypes, tooltip: {
                    content: checkinTypesDetail(),
                    title: "CheckIn Types"
                }
                },
                { type : 'radio', key : 'gps', label : 'GPS tracking' ,  radios : gpsTrackingList }
            ] },
            { row : [
                {type : 'select', key : 'employeeStatus', options : employeeStatusList},
                {type : 'text', key : 'salary', isMandatory : false, emptyCheck : false}
            ]}
            
        ]
    };

    const roleTypeToAccessList = [
        {
            id: 1,
            name: 'Team'
        },
        {
            id: 2,
            name: 'Branch'
        }
    ]

    const rolesSetupInformation = {
        col1 : [
            { row : 
                [
                    {
                        type : 'autocomplete', label:"Role type to access", key : 'roleTypeToAccess', isMandatory : false, 
                    emptyCheck : false, options : roleTypeToAccessList, validationType : 'autocomplete', isLoading,
                    sizeClass: 'col-md-3'
                    }
                ]
            
            }
        ]
    }

    if(employee.roleTypeToAccess && employee.roleTypeToAccess.id === 1) {
        rolesSetupInformation.col1[0].row.push(
            {type : 'autocomplete', label:"Teams Allow Access", key : 'teamRoles', 
            optionText: <>Create Team above and add it here</>, isMandatory : false, 
            emptyCheck : false, options : teamList, validationType : 'autocomplete', 
            isLoading, isMultiple:true}
        )
        
    }
    else if(employee.roleTypeToAccess && employee.roleTypeToAccess.id === 2){
        rolesSetupInformation.col1[0].row.push(
            {type : 'autocomplete', label:"Branchs Allow Access", 
            optionText: <>Create Branch above and add it here</>, 
            key : 'branchRoles', isMandatory : false, emptyCheck : false, 
            options : branchList, validationType : 'autocomplete', 
            isLoading, isMultiple:true}
        )
    }

    

    const onChangeHandler = (event, key)=>{
        const newEmployee = {...employee} ;
        const newErrorMessage = {...errorMessage} ;
        newEmployee[key] = event.target.value ;
        newErrorMessage[key] = '' ;
        setEmployee({...employee, ...newEmployee}) ;
        setErrorMessage({...errorMessage, ...newErrorMessage}) ;
    };
    

    const shiftOnSelect = (value, action, key)=> {
        let newEmployee = {...employee};
        newEmployee.shift_id= value? value.id : undefined;
        newEmployee.shifts= value? value : {};
        let shiftErrorMessage = '' ;
        setEmployee({...newEmployee});
        setErrorMessage({...errorMessage, shiftErrorMessage});
    };
    
     // -*- add list item functions (add shift) -*-

    const autoCompleteOnSelect = (value, action, key)=>{
        let newEmployee = {...employee};
        let newErrorMessage = {...errorMessage};
        newErrorMessage[key] = '';
        newEmployee[key] = (key == 'teamRoles' || key == 'branchRoles') ? [...value] : {...value};
        setEmployee({...employee, ...newEmployee});
        setErrorMessage({...newErrorMessage});
    };

    const multiOnSelect = (value, action, key)=>{
        let newEmployee = {...employee};
        let newErrorMessage = {...errorMessage};
        newEmployee[key] = [...value];
        newErrorMessage[key] = '';
        setEmployee({...newEmployee});
        setErrorMessage({...newErrorMessage});
    };

    const parentDiv = useRef();

    const validateSelectShift = ()=>{
        let inValidMessage = {};
        let isValid = true;
        employee.selectShift.map((shift)=>{
            if(!isAutoCompleteValid(shift, shiftList)){
                inValidMessage[shift.key] = 'Please select this field';
                isValid = false;
            };
        });
        return {message : inValidMessage, isValid : isValid}
    };

    const validateBranchField = ()=>{
        let inValidMessage = '';
        let isValid = true;
        if(!employee.branch || !isAutoCompleteValid(employee.branch, branchList)){
            inValidMessage = 'Please select this field';
            isValid = false;
        }
        return {message : inValidMessage, isValid : isValid};
    };
    
    const mergeValidationResults = ()=>{
        const inputFields = personalInformation.col1.concat( otherInformation.col1);
        let isValid = true;
        let inValidMessages = {};
        
        //validation results
        const branchValidation = validateBranchField();
        //const shiftValidation = validateSelectShift()
        const otherFieldValidation = validation(inputFields, employee);
        // -*- validation results -*-

        inValidMessages = {...otherFieldValidation.errorMessage};
        //inValidMessages.selectShift = {...shiftValidation.message};
        if(branchValidation.message) inValidMessages.branch = branchValidation.message;

        isValid = branchValidation.isValid && otherFieldValidation.isValid;
        
        return {isValid : isValid, messages : inValidMessages};
    };

    const createEmployeeValidation =()=>{

        const validationResult = mergeValidationResults();
        let isCreateEmployee = validationResult.isValid;
        if(!employee.shift_id) { 
            validationResult.messages['shiftErrorMessage'] = "Please select shift";
            isCreateEmployee = false;
        };
        let inValidMessages = validationResult.messages;
        if(!isCreateEmployee){
            const errorMessageLabels = Object.keys(inValidMessages);
            inValidMessages.common = 'Please check this fields ' + errorMessageLabels.map((label)=> splitPascalCase(label)).join(', ');
            setIsShowAccordions([true, true, true]);
            commonMessageElement.current && commonMessageElement.current.focus();
   
        }
        setErrorMessage({...inValidMessages});
        return isCreateEmployee;

    };
    const employeeData = ()=>{
        const {firstName, new_shift, new_shift_start_from, lastName, email, 
            checkinType, gps, branch, team, designation, salary, employeeStatus, 
            QRCode, leaveType, shift_id, address, dob, mobile, teamRoles, branchRoles, 
            roleTypeToAccess} = employee ;

        const data = {first_name : firstName, last_name : lastName, mail_id : email, mobile, 
            checkin_type_id : checkinType, gps : gps, qr_code: QRCode, workspace_id : branch.id, 
            team_id : team.id, designation_id : designation && designation.id ? designation.id : '', shift_id, 
            leave_type_id : leaveType && leaveType.id ? leaveType.id : '', salary : salary, status : employeeStatus, address, dob, 
            
            
        } ;

        if(roleTypeToAccess && roleTypeToAccess.id == 1) {
            data.team_roles = getObjectToArrayIds(teamRoles);
            data.branch_roles = [];
        }
        else if(roleTypeToAccess && roleTypeToAccess.id == 2) {
            data.branch_roles = getObjectToArrayIds(branchRoles);
            data.team_roles = [];
        }
        else {
            data.branch_roles = [];
            data.team_roles = [];
        }

        if(isShowChangeShift) {
            data.new_shift = {...new_shift, id: new_shift.value};
            data.new_shift_start_from = new_shift_start_from;
        }

        return data;
    };

    const getObjectToArrayIds = (originalObject)=>{
        if(originalObject && typeof originalObject === 'object') {
            return Object.values(originalObject).map((item)=>{
                return item.id
            });
        }
        return originalObject
    }

    const howToCheckInByType = {
        1: <p>1) Login details will be sent to your employee's email.<br/>
        2) Please ask them to install TheCheckIn app to log in. <br/>3) Then, in the app, they can simply scan the Branch QR code to check-in.</p>,
        2: <p>1) Go to the 'Employee detail' section for this employee, download the QR code, and print the ID card.<br/>
        2) When the employee arrives at the office, use a mobile device at the QR scan kiosk to easily scan the ID card for check-in.</p>,
        3: <p>1) Login details will be sent to your employee's email.<br/>
        2) Please ask them to install TheCheckIn app to log in. <br/>3) Then,
        In the app can simply check-in.</p>
    }

    const createEmployee =()=>{
        const isCreateEmployee = createEmployeeValidation();    
        if(isCreateEmployee && !isEditEmployee){
            const data = employeeData();
            const dateObject = new Date().getTimezoneOffset();

            let utc = -Math.round(dateObject/60)+':'+-(dateObject%60);
            utc = utc < 0 ?utc : '+'+utc;
            data.timezone = moment().format('Z');

            setStatus({ ...status, showStatus : true ,spinner : true });
            createEmployeeService(data).then((results) => {
                if(results.data.error == 'input field missing'){
                    setServerErrorMessage(`Oops! Something went wrong. 
                    Please try to filed with valid input fields.`);
                    commonMessageElement.current && commonMessageElement.current.focus();
                }
                else{
                    setStatus({ ...status, showStatus : true, spinner : false, success : true, successMessage : <><p>Employee created successfully</p> {howToCheckInByType[data.checkin_type_id]}</> });
                }
            }).catch((error)=>{
                if(error && error.response && error.response.data && error.response.data.error) {
                    setServerErrorMessage(error.response.data.error);
                }
                else {
                    setServerErrorMessage(`Oops! Something went wrong. 
                    Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                }
                commonMessageElement.current && commonMessageElement.current.focus();
                setStatus({ ...status, showStatus : false, spinner : false, success : false, successMessage : "Error" });
            });
        }
        else if(isCreateEmployee && isEditEmployee) {
            setStatus({ ...status, showStatus : true ,spinner : true });
            const data = employeeData();
            updateEmployeeService(data, props.match.params.id).then((results) => {
                setStatus({ ...status, showStatus : true, spinner : false, success : true, successMessage : <><p>Employee updated successfully.</p> {howToCheckInByType[data.checkin_type_id]}</> });
            }).catch((error)=>{
                setStatus({ ...status, showStatus : false, spinner : false, success : false, successMessage : "Error" });
                if(error && error.response && error.response.data && error.response.data.error) {
                    setServerErrorMessage(error.response.data.error);
                }
                else {
                    setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
                }
                commonMessageElement.current && commonMessageElement.current.focus();
            })
        }
    };

    const routeEmployeePage = ()=> {
        router.push('/Employees') ;
        setStatus({ showStatus : false, spinner : false, success : false, successMessage : '' }) ;
    }

    const saveImage = (imageURL)=> {
        let newEmployee = employee ;
        newEmployee.imageURL = imageURL ;
        setEmployee({...employee, newEmployee});
    }

    const showAccordionBody =(index)=>{
        const showAccordions = isShowAccordions; 
        showAccordions[index] = !showAccordions[index];
        setIsShowAccordions([...showAccordions]);
    };

    const renderPersonalformation = ()=> {
        return  (
            <div className = 'row'>
                <div className = 'col-12 col-lg'>
                    <div className = 'row'>
                        <div className = 'col-md-12'>
                            <Form rows = {personalInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler} isLoading={isLoading}  />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderOtherInformation = ()=> {
        return(
            <div className = 'row'>
                <div className = 'col-lg col-12 '>
                    <div className = 'row'>
                        <div  className = 'col-md-12'>
                            <Form rows = {otherInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler} autoCompleteOnSelect = {autoCompleteOnSelect} multiOnSelect = {multiOnSelect}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };



    const renderRolesSetupInformation = () => {
        return(
            <div className = 'row'>
                <div className = 'col-lg col-12 '>
                    <div className = 'row'>
                        <div  className = 'col-md-12'>
                            <p>This section helps to setup role to accesss specfic Branches or Teams to this employee </p>
                            <Form rows = {rolesSetupInformation.col1} value = {employee} errorMessage = {errorMessage} onChange = {onChangeHandler} autoCompleteOnSelect = {autoCompleteOnSelect} multiOnSelect = {multiOnSelect}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderAddShiftInformation = ()=>{
        return(
            <div className = ''>
                <div className = 'row'>
                    <div className = 'col-12'>
                        <div className = 'col-md-3'>
                            <label className="label mb-1">Select Shift<span className="text-danger mandatory-input">*</span></label>
                            <AutoComplete name = {"shifts"} value = {employee.shifts} onSelect = {(value, action)=> shiftOnSelect(value, action)} options = {shiftList}/>
                            <ErrorMessage label = {errorMessage.shiftErrorMessage && errorMessage.shiftErrorMessage}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const onChangeShift = () => {
        showChangeShift(true)
    }

    const onHideChangeShift = () => {
        setEmployee({...employee, new_shift: false, new_shift_start_from:""})
        showChangeShift(false)
    }

    const shiftOnNewSelect = (value, action, key)=> {
        let newEmployee = {...employee};
        newEmployee.new_shift = value;
        setEmployee({...newEmployee});
    };

    const renderShiftInformation =()=>{
        const currentShift = employee.shiftDetail && employee.shiftDetail.currentShift ? true : null;
        return currentShift ? (
            <div className = 'd-flex'>
                <div className = 'col-lg-6'>
                    <div className="row align-items-center">
                        <div className="col">
                            <div>
                                <label className="label">Current Shift:</label>
                                <span>{employee.shiftDetail.currentShift.name} from {employee.shiftDetail.currentShift.start_from}</span>
                            </div>
                        </div>
                        <div className="col">
                            {!isShowChangeShift && <Button className = 'btn-primary btn-sm' onClick={onChangeShift}>Change Shift</Button>}
                        </div>
                        {employee.shiftDetail.nextShift && <div>
                            Next Shift {employee.shiftDetail.nextShift.name} starts from {employee.shiftDetail.nextShift.start_from} 
                        </div>}
                    </div>
                    {isShowChangeShift && (<div className="row align-items-center change-shift-container">
                        <div className="change-shift-title">Change Shift</div>
                        <div className="col">
                                <label className="label mb-1">Select New Shift</label>
                                <AutoComplete name = {""} value = {employee.new_shift || ""} onSelect = {(value, action)=> shiftOnNewSelect(value, action)} options = {shiftList}/>
                        </div>
                        <div className="col">
                            <label className="label">Starts From:</label>
                            <DatePickerReact
                                selected={employee.new_shift_start_from}
                                onChange={(e)=>onChangeHandler({target:{ value:e}}, "new_shift_start_from")}
                                minDate={moment().add(1, 'd').toDate()}
                                placeholderText="Select a day"
                                />
                        </div>
                        <p>Click Update button to change new shift</p>
                        <div className="col"><Button className = 'btn-secondary btn-sm' onClick={onHideChangeShift}>cancel</Button></div>
                        
                        <ErrorMessage label = {errorMessage.selectShift && errorMessage.selectShift}/>
                    </div>)}
                </div>
            </div>
        ) : renderAddShiftInformation()
    };

    const actions = [{label : 'Back', className : 'btn-secondary', onClick : routeEmployeePage}, {label : isEditEmployee ? 'Update' : 'Save', className : 'btn-primary', onClick : createEmployee}];
    const accordion = [
        {title : 'Personal Infomation', render : renderPersonalformation()},
        {title : 'Other Infomation', render : renderOtherInformation()},
        {title : 'Shift Infomation', render : renderShiftInformation()}
    ];

    if(roleName === 'admin') {
        accordion.push({title : 'Roles Setup', render: renderRolesSetupInformation()})
    }


    const commonError = errorMessage.common? '' : 'visually-hidden-focusable';
    return(
        <Layout>
            {roleName !== 'employee' ? <><div className = { status.showStatus ? 'd-block f-s14 container' : 'd-block f-s14 container'} ref = {parentDiv}>
                <PageHeader title = {isEditEmployee ? "Edit Employee" : "Add Employee"}/>
                {!!serverErrorMessage && <ErrorAlert close={hideErrorMessage}>
                {serverErrorMessage}
               </ErrorAlert>}
                    <div className = {'p-2 text-danger border border-2 border-danger ' + commonError} ref = {commonMessageElement} tabIndex = '-1'>
                        {errorMessage.common}
                    </div>

                <form autoComplete="off">
                    {accordion.map((accordionBody, index)=>{
                        return(
                            <Accordion key = {index} onClick = {()=> showAccordionBody(index)} className = 'border-0 border-bottom rounded-0' title = {accordionBody.title} showBody = {isShowAccordions[index]}>
                                {accordionBody.render}
                            </Accordion>
                        )
                    })}

                    <div className = 'pt-2 pb-2 px-3 d-flex justify-content-end'>
                        <div>
                            <Actions actions = {actions}/>
                        </div>
                    
                    </div>
                </form>

                
            </div>
            {status.showStatus && <Modal hideModal={routeEmployeePage}>
                <div className="mb-3">
                    <div className = {status.showStatus ? 'd-block status-spinner' : 'd-none'}>
                        <Status spinner = {status.spinner} success = {status.success} successMessage = {status.successMessage} onClick = {routeEmployeePage}/>
                    </div>
                </div>
            </Modal>}
            {showToast.isShow &&<Toast message={showToast.message} closeToast={closeToastHandler}/>}
            {isShowBranchModal && <AddBranch closeModal={closeBranchModal} addBranch={addBranchHandler} name={createBranchName} />}
            </>
        : <p>You are not allowed to access this page</p>}
            
        </Layout>
    )
}

export default AddEmployee;
