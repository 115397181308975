import { useState }  from 'react' ;
import Logo from '../images/logo-square.png';
import Button from '../Components/Common/Button';
import {useHistory} from 'react-router-dom';

const First=(props)=>{
    const router = useHistory();


    const onClickHandler = async (type)=> {
        
        if(type === 'signup') router.push ('./Signup')
        else router.push ('./Login')

    }

    return(
        <div>
            <div className="container-xxl mt-10">
                    <div className="col-md-12 mt-10">
                        <div className="row">
                            <div className='text-center'>
                                <a href='https://web.thecheckin.io/'><img className='first-square-logo' src={Logo} alt='logo'/></a>
                            </div>
                        </div>
                    </div>
                </div>
            <form >
                <div className='first-button-container'>
                    <Button onClick={()=>onClickHandler('signup')} className = "w-100 btn-success mt-10 mb-3 btn-sm" type="button">Sign Up</Button>
                    <div className='text-center'>or</div>
                    <Button onClick={()=>onClickHandler('signin')} className = "w-100 btn-primary mt-10 mb-3 btn-sm" type="button">Sign In</Button>
                </div>
            </form>

        </div>
    )
}
export default First;