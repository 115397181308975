import PageHeader from "../Components/Common/PageHeader";
import Layout from "../Components/Layout";
import Pazhanam from '../images/pazhanam.png';

const About = () => {
    return (<Layout>
            <PageHeader title = 'Support'/>
            <div className="container">
                <div className="card mt-30">
                    <div className="card-body">
                        <h5 className="card-title">Delete Your Account:</h5>
                    </div>
                    <div className="card-body">
                    To delete your account, email <strong>support@thecheckin.io</strong> from your registered email with the subject <strong>Account Deletion Request</strong>. Include your name and registered email in the message. The process may take 5-7 business days, and data will be permanently deleted.
                    </div>
                </div>
                <div className="card mt-30">
                    <div className="text-center">
                        <img src={Pazhanam} className="pazhanam-logo" alt=""/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">About Us:</h5>
                        <h5 className="card-title">Pazhanam Technologies Pvt Ltd</h5>
                        <p className="card-text">We are dedicated to building innovative and user-friendly applications from India.</p>
                        <p className="card-text">Our passion drives us to create products that simplify your life.</p>
                    </div>
                    <div className="card-body">
                        If you need support or you are having any feedback with TheCheckIn app, please contact us to <strong>support@thecheckin.io</strong>
                    </div>
                </div>
            </div>
        </Layout>)
 }
  
  export default About