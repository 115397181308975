import imageRender from '../images/signup-page-thecheckin-io.jpg';
import Footer from './Common/Footer.js';
import Logo from '../images/logo.png';
const BackgroundEntryPage = (props) =>{
        return(
            <>
                <div className="container-xxl mt-10">
                    <div className="col-md-12 mt-10">
                        <div className="row">
                            <div className='col'>
                                <a href='https://web.thecheckin.io/'><img style={{height:'30px'}} src={Logo} alt='logo'/></a>
                            </div>
                        </div>
                    </div>
                </div>

            <div className="container-xxl mt-10">
                <div className="col-md-12 mt-10">
                    <div className="row">
                        <div className="col-md-3 mx-auto">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
export default BackgroundEntryPage